/* storybook-check-ignore */
import React, { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { useAuth } from '@opendoor/auth-fe';
import { Button, Flex, Input, InputProps, Text } from '@opendoor/bricks/core';

import { OdProtosBuyerV2Data_ReactionSource } from '__generated__/athena';

import { Listing } from '../../declarations/exclusives/listing';
import { useWatchlistContext, WatchlistContextProps } from './contexts/WatchlistContextProvider';
import { validateEmail } from './helpers/emails';
import useExclusiveAnalytics from './hooks/useExclusiveAnalytics';
import Modal from './Modal';

interface WatchlistModalProps {
  isOpen: boolean;
  listing: Listing;
  streetName: string;
  onRequestClose: () => void;
  setIsOnWatchlist: (isOnWatchlist: boolean) => void;
  ignoreSellerOfferExperiment?: boolean;
  origin?: Parameters<ReturnType<typeof useExclusiveAnalytics>['trackExclusivesEvent']>[0];
  _analyticsLabel?: string;
  analyticsAdditionalContextualData?: Record<string, unknown>;
  source?: 'pdp' | 'gallery' | 'self-unlock';
}

const styles = {
  ctaWrapper: css`
    .cta-button {
      background-color: var(--colors-brand50);
    }
  `,
};

export function getAnalyticsLabel(buttonDisplayText: WatchlistContextProps['buttonDisplayText']) {
  switch (buttonDisplayText) {
    case 'Request Pricing':
      return 'request-pricing';
    case 'Get email updates':
      return 'open-house-notification';
    case 'Get open house email updates':
      return 'open-house-reminder';
    default:
      return 'add-to-watchlist';
  }
}

export const emailUpdateSourceToReactionSource = (
  source: 'pdp' | 'gallery' | 'self-unlock',
): OdProtosBuyerV2Data_ReactionSource => {
  if (source === 'pdp') {
    return 'REACTION_SOURCE_WEB_PDP_EMAIL_UPDATES';
  } else if (source === 'gallery') {
    return 'REACTION_SOURCE_GALLERY';
  } else if (source === 'self-unlock') {
    return 'REACTION_SOURCE_SELF_UNLOCK_GET_EMAIL_UPDATES';
  }

  return 'REACTION_SOURCE_UNKNOWN';
};

const WatchlistModal = ({
  isOpen,
  listing,
  onRequestClose,
  origin = 'exclusives-favorite',
  source: sourceParam,
  _analyticsLabel,
  analyticsAdditionalContextualData,
}: WatchlistModalProps) => {
  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const isEmailValid = validateEmail(email);
  const { trackExclusivesEvent } = useExclusiveAnalytics();
  const {
    buttonDisplayText,
    addToWatchlist,
    source: sourceState,
    isLoading,
  } = useWatchlistContext();
  const source = sourceParam ? emailUpdateSourceToReactionSource(sourceParam) : sourceState;
  const handleEmailChange: InputProps['onChange'] = (_, value, isValid) => {
    setEmail(isValid ? value || '' : '');
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!isEmailValid) {
      return;
    }

    await addToWatchlist(email, { source }).finally(() => {
      const analyticsLabel = _analyticsLabel || getAnalyticsLabel(buttonDisplayText);

      trackExclusivesEvent(origin, analyticsLabel, listing, {
        email,
        source,
        ...analyticsAdditionalContextualData,
      });
      onRequestClose();
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setEmail('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
  }, [user]);

  const iconTitle = (
    <Flex flexDirection="column">
      <Text fontSize="s2" fontWeight="semibold" color="brand50">
        Save home
      </Text>
      <Text fontSize="s0" color="neutrals100" opacity="0.8">
        Save this home and we will send you email updates about it.
      </Text>
    </Flex>
  );
  return (
    <Modal isOpen={isOpen} icon={iconTitle} onRequestClose={onRequestClose}>
      <Flex flexDirection="column" gridGap="3rem" css={styles.ctaWrapper} mt="1rem">
        <Input
          css={css`
            &:focus {
              outline: none;
              border: none;
            }
            &::placeholder {
              color: var(--colors-brand50);
              opacity: 0.6;
            }
          `}
          fontSize="s2"
          placeholder="Email Address"
          color="brand50"
          outline="none"
          border="none"
          id="watchlistModalInput"
          aria-labelledby="watchlist-modal"
          type="email"
          paddingX="0"
          analyticsName="cosmos-exclusives-watchlist-modal-input"
          unboundedWidth
          value={email}
          onChange={handleEmailChange}
        />
        <Flex flexDirection="column" gridGap="1rem">
          <Text fontSize="s000" color="neutrals100" opacity="0.6">
            By pressing “Save”, I consent to receiving automated emails regarding Opendoor
            Exclusives services. I may opt-out any time, and it is not a condition of any purchase.
          </Text>
          <Button
            loading={isLoading}
            disabled={!email || isLoading}
            aria-label="Add to your watchlist"
            analyticsName="cosmos-exclusives-watchlist-modal-save-button"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default WatchlistModal;
