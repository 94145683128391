/* storybook-check-ignore */
import { createContext, ProviderProps, useContext, useMemo } from 'react';

import { useListingContext } from 'components/exclusives/contexts/ListingContext';
import useWatchlist from 'components/exclusives/hooks/useWatchlist';
import {
  NotificationStatus,
  useNotificationSectionContext,
} from 'components/exclusives/NotificationSection';
import { getWatchlistButtonDisplayText } from 'components/exclusives/WatchlistButton';

import { Listing } from 'declarations/exclusives/listing';

export interface WatchlistContextProps
  extends Pick<
    ReturnType<typeof useWatchlist>,
    | 'isOnWatchlist'
    | 'setIsOnWatchlist'
    | 'addToWatchlist'
    | 'isLoading'
    | 'toggleWatchlist'
    | 'source'
    | 'error'
  > {
  buttonDisplayText:
    | 'Add to Watchlist'
    | 'Request Pricing'
    | 'Added to watchlist!'
    | 'Pricing Requested'
    | 'Get open house email updates'
    | 'Get email updates'
    | 'Notification set'
    | 'Reminder set'
    | 'Join waitlist';
  listing: Listing;
}

export const WatchlistContext = createContext<WatchlistContextProps | undefined>(undefined);

export type WatchlistContextProviderProps = Pick<ProviderProps<WatchlistContextProps>, 'children'>;

export const WatchlistContextProvider = ({ children }: WatchlistContextProviderProps) => {
  const { listing } = useListingContext();
  const { displayNotification } = useNotificationSectionContext();
  const isListingOnWatchlist = useWatchlist(listing, {
    onAddedToWatchlist: () =>
      displayNotification(NotificationStatus.Success, 'Subscribed to email updates'),
    onRemovedFromWatchlist: () =>
      displayNotification(NotificationStatus.Info, 'Removed from email updates'),
    onError: () =>
      displayNotification(
        NotificationStatus.Error,
        'Sorry! A error ocurred, please try again later.',
      ),
    source: 'REACTION_SOURCE_WEB_PDP_EMAIL_UPDATES',
  });

  const buttonDisplayText = useMemo(
    () => getWatchlistButtonDisplayText(listing, isListingOnWatchlist.isOnWatchlist),
    [listing.listing_price_cents, isListingOnWatchlist.isOnWatchlist],
  );

  return (
    <WatchlistContext.Provider
      value={{
        ...isListingOnWatchlist,
        listing,
        buttonDisplayText,
      }}
    >
      {children}
    </WatchlistContext.Provider>
  );
};

export const useWatchlistContext = () => {
  const context = useContext(WatchlistContext);

  if (!context) {
    throw new Error('useWatchlistState must be used within a WatchlistContextProvider');
  }

  return context;
};
